<template>
  <div>
    <!-- <h1>Hello Help &amp; Support</h1> -->
    <!-- <div id="live-chat-widget-script" ></div> -->

    <h3 class="pl-2 pt-2 pr-2">FAQ's</h3>
    <!-- types -->
    <app-collapse
      class="pl-2 pr-2"
      accordion
      :type="collapseType"
      v-for="(item, index) in helpfaq"
      :key="index"
    >
      <app-collapse-item :title="item.PageTitle">
        <div v-html="item.PageDescription">
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>


<script>
import { loadScript } from "@/libs/helpers";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { BFormRadioGroup, BFormRadio, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
  },
  data() {
    return {
      interval: null,
      collapseType: "margin",

      helpfaq: [
        {
          id: 1,
          PageTitle: "Can you guarantee the authenticity of Revivify items?",
          PageDescription:
            "Yes, absolutely. Our 2-step authentication process is carried out by our in-house experts, so we can make sure you only shop genuine items.",
        },
        {
          id: 2,
          PageTitle: "Do you deliver internationally?",
          PageDescription:
            "Yes, we are pleased to offer worldwide shipping. Information regarding costs and delivery times can be found on our delivery page.",
        },
        {
          id: 3,
          PageTitle: "Will I need to pay import tax on my purchase?",
          PageDescription:
            "This will depend on where you call home. Please visit our delivery page for more details.",
        },
        {
          id: 4,
          PageTitle: "How do I sell my item?",
          PageDescription:
            'Selling with us is simple! All you need to do is follow these steps:<br></br>   <i>Open your seller account</i><br></br><p>Your seller account is an all-in-one space for managing and keeping an eye on your listings. See how much you have made, track your potential earnings, or even opt to give your items better visibility.</p> <br></br> <i>Submit your piece</i><br></br><p>Upload your listing by filling in the short online form and submitting it to our team. To support our expert, 2-step authentication process, we ask that you attach a selection of clear images.</p> <br></br> <i>Start selling and earning</i> <br></br> <p>Once your items have been approved, your Revivify journey begins! Our team will handle the whole process from beginning to end, including professional photography, buyer communication, and delivery.</p>',
        },
        {
          id: 5,
          PageTitle: "What items can I sell?",
          PageDescription:
            "We welcome clothing, accessories, bags and jewellery from our <a>brand list</a> in a resalable <a>condition</a>.",
        },
        {
          id: 6,
          PageTitle: "How do you reach a valuation?",
          PageDescription:
            "We closely examine global market data to ascertain the price at which the same or similar items are being sold. Our unique expertise allows us to price competitively, taking into consideration the brand, popularity, condition and more.",
        },
        {
          id: 7,
          PageTitle: "What if I do not agree with the valuation?",
          PageDescription:
            "No problem! We only collect the item once a valuation has been agreed.",
        },
        {
          id: 8,
          PageTitle: "How much can I expect to earn?",
          PageDescription:
            "With Revivify, the more you sell the more you earn. Our commission structure is as follows:<br><br> <p>35% commission on the sale price of your items (together or individually) valued at a minimum of £2000.</p><p>40% commission on the sale price of your items (together or individually) valued between £500 and £2000.</p> <br><p>45% commission on the sale price of your items (together or individually) valued less than £500.  </p>",
        },
        {
          id: 9,
          PageTitle: "I added a listing, why haven't I received an offer?",
          PageDescription:
            "The first step is to check your seller dashboard. If there is no change or notification of an offer within 4 business days, please get in touch with the Revivify team.",
        },
        {
          id: 10,
          PageTitle: "What is included as part of our white glove service?",
          PageDescription:
            "We take care of the entire sales process from end to end, including the valuation, photography and delivery of your item. Our friendly and knowledgeable team are always on hand to answer any questions you might have, with an emphasis on making the process as seamless as possible for our buyers and sellers alike. This includes offering a collection service in Mumbai, London, New York and Delhi if you have over 5 items to sell or amounting to the value of £2000. ",
        },
        {
          id: 11,
          PageTitle: "When will I receive my payment?",
          PageDescription:
            "Once your item has been purchased, you will be able to create your one-click invoice within your seller dashboard and receive payment almost instantly – without fuss or back and forth communication. ",
        },
        {
          id: 12,
          PageTitle: "How should I photograph my item for approval?",
          PageDescription:
            "We ask that you take images of the front and back of your item, including any wear and tear or damage. It is important to highlight aspects such as loose threads and missing embellishments. We also recommend choosing a spot with plenty of natural sunlight and, if possible, positioning it against a solid colour background.",
        },
        {
          id: 13,
          PageTitle: "How can I contact Revivify? ",
          PageDescription:
            "You can contact our friendly team through your seller dashboard or by sending an email to wecare@revivify.com",
        },
        {
          id: 14,
          PageTitle: "Can the buyer return their item?",
          PageDescription:
            "Due to the nature of our platform, we are not able to accept returns. If your item is not quite what you were anticipating, then you are welcome to a complimentary relisting with Revivify so long as notify our team within 48 hours of receipt.",
        },
        {
          id: 15,
          PageTitle: "How is shipping calculated? ",
          PageDescription:
            "We offer free shipping for all order purchases over INR 25,000/ GBP 280 ",
        },
        {
          id: 16,
          PageTitle: "Do you accept credit card payments?",
          PageDescription:
            "We accept payments from all major credit cards (including American Express) via our secure payment partners; Stripe, PayPal and Authorize.net. Please be aware that credit card companies may apply additional charges such as currency fees for international purchases.",
        },
        
      ],
    };
  },
  methods: {
    loadChat() {
      this.interval = setInterval(() => {
        if (window.MessageBirdChatWidget) {
          window.MessageBirdChatWidget.show();
          clearInterval(this.interval);
        }
      }, 500);
    },

    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    removeChat() {
      window.MessageBirdChatWidget.hide();
    },
  },
  destroyed() {
    this.removeChat();
  },
  beforeMount() {
    this.loadChat();
  },
};
</script>



<style lang="css" scoped>
.card{
  border-radius: 5px !important;
}
</style>